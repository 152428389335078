import * as React from "react";

import "./dropdown.css";
import { createPortal } from "react-dom";

interface IProps {
    options: { [key: string]: string };
}




const Dropdown = (props: IProps & { children?: React.ReactNode }) => {

    const [open, setOpen] = React.useState(false);

    const BackDrop: React.FC = () => {
        return createPortal(
            <div onClick={() => setOpen(!open)} className="backdrop"></div>,
            document.getElementById("root") as any
        );
    }

    function renderDropdown() {
        if (!open) return null;
        return <div className="dropdown">
            {Object.keys(props.options).map((key, idx) => {
                return <a key={idx} className="option" href={props.options[key]}>{key}</a>
            })}
        </div>;
    }

    return (
        <>
            <div className="dropdown-wrapper">
                <div className="toggle" onClick={() => setOpen(!open)}>{props.children}</div>
                {renderDropdown()}
            </div>
            {open ? <BackDrop /> : null}

        </>
    );
}

export default Dropdown;
