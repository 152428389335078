import * as React from "react";
import { FaCommentAlt } from "react-icons/fa";
import ReactModal from "react-modal";

const Notes = (props: { content?: string, onSave?: (content?: string) => void }) => {
    const { content, onSave } = props;

    const [isOpen, setIsOpen] = React.useState(false);
    const [textAreaContent, setTextAreaContent] = React.useState(content || '');

    React.useEffect(() => {
        setTextAreaContent(content || '');
    }, [content]);

    const style: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        content: {
            position: 'initial',
            width: '400px',
            height: '400px',
            border: 0,
            background: '#202938',
            overflow: 'none',
            outline: 'none',
            padding: '20px'
        }
    };

    function handleOnClose() {
        setIsOpen(false);
        if (onSave) onSave(textAreaContent);
    }

    function handleOnChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setTextAreaContent(e.target.value);
    }

    return (<div>
        <FaCommentAlt style={{ color: textAreaContent ? 'white' : 'grey', fontSize: "20px", cursor: 'pointer' }} onClick={() => setIsOpen(true)} />
        <ReactModal parentSelector={this} style={style} ariaHideApp={false} onRequestClose={handleOnClose} isOpen={isOpen}>
            <textarea style={{ color: 'black', width: '100%', height: '100%' }} onChange={(e) => handleOnChange(e)} maxLength={250} value={textAreaContent}></textarea>
        </ReactModal>
    </div >);
}

export default Notes;