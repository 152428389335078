// If we are logged in, show vaclist, otherwise show top tracked & last checked with some info.

import * as React from "react";
import Page from "../shared/Page";

const Privacy: React.FC = () => {
    return (
        <Page header="Privacy Policy">
            <h2 className="text-xl">Signing in through Steam</h2>

            <p>
                Signing in through Steam only provides your steam_id to VacList. That id is then used to generate an account on VacList for data persistance.

            </p>

            <ul className="list-disc">
                <li><a href="https://steamcommunity.com/dev">https://steamcommunity.com/dev</a></li>
                <li><a href="https://gaming.stackexchange.com/questions/236779/what-information-is-supplied-to-sites-when-logging-in-with-steam-openid/236785#236785">
                    "What information is supplied to sites when logging in with steam openid"
                </a></li>
            </ul>
            <br />
            <h2 className="text-xl">Cookies</h2>

            <p>Cookies are used once you have logged in to maintain your session with VacList.</p>

            <h2 className="text-xl">Contact</h2>

            <p>You can contact me at sbuggay [at] gmail.com</p>

        </Page>
    );
};

export default Privacy;