// If we are logged in, show vaclist, otherwise show top tracked & last checked with some info.

import * as React from "react";
import { profileContext } from "../store/state";
import VacList from "./grid/VacList";
import Intro from "./Intro";

const Home: React.FC = () => {
    const context = React.useContext(profileContext);

    if (context.isLoading) return null;
    return context.profile ? <VacList /> : <Intro />;
};

export default Home;