import * as React from "react";
import state from "../../store/state";
import { EInputTypes, guessInput } from "../../utilities/steamid";
import Page from "../shared/Page";
import "./batch.css";

const limit = 100;

const Batch: React.FC = () => {

    const [value, setValue] = React.useState("");
    const [message, setMessage] = React.useState("");

    const trackAction = async () => {
        const batch = value.split("\n").filter(row => {
            return guessInput(row) === EInputTypes.SteamID64;
        });

        if (batch.length > limit) {
            setMessage("Too many ids");
            return;
        }

        const result = await state.trackBatch(batch);

        setMessage(result ? "Success" : "Failure");
    }

    return <>
        <Page header="Batch Import">
            <p>Batch import up to 100 steamids. One line per steamid. Only supports SteamID 64.</p>
            <textarea onChange={e => setValue(e.target.value)}></textarea>
            <div>
                <button onClick={trackAction}>Submit</button>
                <span style={{ paddingLeft: "8px" }}>{message}</span>
            </div>
        </Page>
    </>;
};

export default Batch;
