import * as React from "react";
import { profileContext } from "src/store/state";
import Page from "../shared/Page";

function useInput(placeholder: string = '') {
    const [value, setValue] = React.useState("");
    const input = <input placeholder={placeholder} value={value} onChange={e => setValue(e.target.value)} />;
    return [value, input];
}

const Admin = () => {

    const { isAdmin } = React.useContext(profileContext);
    console.log(isAdmin);

    const [reindexValue, reindexInput] = useInput('');

    if (!isAdmin) return null;
    
    const handleOnClick = () => {
        fetch('/api/reindex', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                steam_id: reindexValue
            })
        });
    }

    return (
        <Page header="Admin Panel">
            <div>
                {reindexInput}
                <button onClick={handleOnClick}>Reindex</button>
            </div>
        </Page>
    );
}

export default Admin;