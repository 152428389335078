import state, { profileContext } from '../../store/state';
import useSWR from 'swr';

export default function AccountProvider(props: { children: JSX.Element }) {
    const { children } = props;

    const { data: profile, isLoading } = useSWR('/steam/profile', () => state.getProfile());
    const { data: trackedIds, mutate: refreshTrackedIds } = useSWR('/api/trackedIds', async () => {
        const res = await fetch('/api/trackedIds');
        const json = await res.json() as string[];
        return new Set(json);
    });

    const { data: isAdmin } = useSWR(`/api/admin`, async () => {
        const res = await fetch('/api/admin');
        const json = await res.json();
        return json === true;
    });

    const context = {
        profile,
        trackedIds,
        refreshTrackedIds,
        isAdmin,
        isLoading
    }

    return (
        <profileContext.Provider value={context}>
            {children}
        </profileContext.Provider>
    );

}