import { addDays, endOfToday, format } from "date-fns";
import { IStatsResult } from "../../../../server/db/Stats";
import { ChartData, ChartDataset } from "chart.js/auto";
import { Line } from "react-chartjs-2";

function DailyTelemetry(props: { data: IStatsResult }) {
    const { data } = props;

    const today = endOfToday();

    const days = [];
    const a = [];
    const b = [];
    const c = [];

    while (data.dailyTelemetry.length < 7) {
        data.dailyTelemetry.unshift({
            cache: 0,
            db: 0,
            steam: 0
        });
    }

    for (let i = 0; i < 7; i++) {
        days.push(`${format(addDays(today, -1 * i), "MM/DD")} ${(i === 0 ? "(Today)" : "")}`);
        a.push(data.dailyTelemetry[i].cache);
        b.push(data.dailyTelemetry[i].db);
        c.push(data.dailyTelemetry[i].steam);
    }

    const cacheSet: ChartDataset<'line'> = {
        label: "Cache",
        data: a,
        borderColor: "green",
        backgroundColor: "rgba(0, 255, 0, 0.0)"
    };

    const dbSet: ChartDataset<'line'> = {
        label: "VacList",
        data: b,
        borderColor: "yellow",
        backgroundColor: "rgba(255, 255, 0, 0.0)"
    };

    const apiSet: ChartDataset<'line'> = {
        label: "Steam",
        data: c,
        borderColor: "red",
        backgroundColor: "rgba(255, 0, 0, 0.0)"
    };

    const apiChartData: ChartData<'line'> = {
        labels: days.reverse(),
        datasets: [
            cacheSet,
            dbSet,
            apiSet
        ],
    }

    return <div style={{ position: "relative", height: "300px", width: '100%' }}>
        <Line options={{
            maintainAspectRatio: false,
            tension: 0.25,
        } as any} data={apiChartData} />
    </div>
}

export default DailyTelemetry;