import * as React from "react";

import { addDays, format, distanceInWordsToNow } from "date-fns";
import Chart, { ChartData, ChartDataset, LineController, BarController, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from "chart.js/auto";
import StatTable from "./StatTable";

import "./stats.css";
import Page from "../shared/Page";

import useSWR from "swr";
import StatHeroList from "./StatHeroList";

import { IStatsResult } from "../../../../server/db/Stats";
import DailyTelemetry from "./DailyTelemetry";
import { Bar, Line } from "react-chartjs-2";

Chart.register(LineController, BarController, CategoryScale, LinearScale, PointElement, LineElement, BarElement);

Chart.defaults.color = 'white';
Chart.defaults.maintainAspectRatio = false;
Chart.defaults.hover.mode = 'index';
Chart.defaults.hover.intersect = false;
Chart.defaults.plugins.tooltip.mode = 'index';
Chart.defaults.plugins.tooltip.intersect = false;

const Stats: React.FunctionComponent = () => {

    const fetcher = () => fetch("/api/info").then(async response => {
        return await response.json();
    });

    const { data } = useSWR<IStatsResult>("/api/info", fetcher);

    if (!data) return (
        <>
            <Page header={"Statistics"}>
                <div style={{ height: "300px" }}></div>
            </Page>
            <Page header={"Daily API Metrics"}>
                <div style={{ height: "300px" }}></div>
            </Page>
            <Page header={"Performance Metrics"}>
                <div style={{ height: "300px" }}></div>
            </Page>
        </>
    );

    const banSet: ChartDataset<'bar'> = {
        data: data.banHistogram.map((row: any) => parseInt(row.count)),
        backgroundColor: "#f71735",
        // hideInLegendAndTooltip: true,
        barThickness: "flex",
    };

    const dailyBanSet: ChartDataset<'line'> = {
        data: data.dailyBans.map((row: any) => parseInt(row.count)).reverse(),
        backgroundColor: "#ffffff",
        fill: {
            target: "origin",
        },
        pointRadius: 0,
        tension: 0.25,
    };

    const banChartData: ChartData<'bar'> = {
        labels: Object.keys(data.banHistogram),
        datasets: [
            banSet,
        ]
    }

    const dailyBanChartData: ChartData<'line'> = {
        labels: Object.keys(data.dailyBans).map(n => format(addDays(Date.now(), -n), "M/d/YY")).reverse(),
        datasets: [
            dailyBanSet
        ],
    }

    const todaysTelemetry = data.dailyTelemetry.reverse()[0];

    const resetDate = new Date();
    resetDate.setUTCHours(0);
    resetDate.setUTCMinutes(0);
    resetDate.setUTCSeconds(0);
    resetDate.setUTCMilliseconds(0);

    return (
        <>
            <Page header={"Statistics"}>
                <div className="stats">
                    <StatHeroList data={data} />
                </div>
            </Page>

            <Page header={"Daily API Metrics"}>
                <p>
                    <a href="https://steamcommunity.com/dev/apiterms">Valve rate limits their API to 100,000 calls per day</a>, so we need to be careful when and how often we perform account lookups.
                    This section shows the effeciency of our cache and database lookups per day (but not the client cache, which is not tracked here).
                    For every account that we find in the cache or database,
                    that is potentially three Steam API calls we can circumvent.
                </p>

                <br />

                <DailyTelemetry data={data} />
                <br />

                <div>
                    <meter style={{ width: "100%" }} value={todaysTelemetry.steam} min="0" max="100000"></meter>
                    <div style={{ textAlign: "center" }}>
                        Daily API Limit: {todaysTelemetry.steam} / 100,000
                    </div>
                </div>

                <p>The next reset is at {format(addDays(resetDate, 1), "hh:mm A")} ({distanceInWordsToNow(addDays(resetDate, 1))})</p>
            </Page>

            <Page header={"Daily Bans"}>
                <p>
                    Number of accounts banned per day for the last year.
                </p>
                <br />
                <div style={{ position: "relative", height: "300px" }}>
                    <Line options={{
                        plugins: {
                            legend: {
                                display: false
                            },
                        }
                    }} data={dailyBanChartData} />
                </div>
            </Page>

            <Page header={"Ban Histogram by Account Age"}>
                <p>
                    The number of accounts that have either a VAC or game ban by account age in years.
                </p>
                <br />
                <div style={{ position: "relative", height: "300px" }}>
                    <Bar options={{
                        plugins: {
                            legend: {
                                display: false
                            }
                        }
                    }} data={banChartData} />
                </div>
            </Page>

            <Page header={"Performance Metrics"}>
                <p>
                    Various server performance metrics are tracked and displayed here. Gets reset weekly.
                </p>
                <StatTable perfData={data.perfData} />
            </Page>
        </>
    )
}

export default Stats;