import * as React from "react";
import { useNavigate } from "react-router-dom";
import SteamApi from "../../steam/Api";
import { guessInput, EInputTypes } from "../../utilities/steamid";
import { FaSearch } from 'react-icons/fa';

enum ENotificationState {
    None,
    Info,
    Error,
    Success
}

const Input = () => {

    // const history = useHistory();
    const navigate = useNavigate();

    const inputEl = React.useRef(null);
    const [label, setLabel] = React.useState("");
    const [state, setState] = React.useState<ENotificationState>(ENotificationState.None);

    const handleSubmit = React.useCallback(async (e: React.FormEvent) => {
        e.preventDefault();
        const input = (inputEl.current as any).value;

        if (input.length <= 2) {
            // not a valid search
            return;
        }

        const res = await SteamApi.search(input);

        if (res) {
            navigate(`/account/${res}`);
            setState(ENotificationState.Success);
            setLabel("Account found");
        }
        else {
            // didn't find anything
            setState(ENotificationState.Error);
            setLabel("No account found");
        }

    }, [navigate]);

    const updateGuess = () => {
        const input = (inputEl.current as any).value;

        // Vanity names can't be less than 2 characters, and nothing else can either so just return none.
        if (input.length < 2) {
            setLabel("");
            setState(ENotificationState.None);
            return;
        }
        const guess = guessInput(input);
        const label = (() => {
            switch (guess) {
                case EInputTypes.SteamID:
                    return "SteamIDv1";
                case EInputTypes.SteamID3:
                    return "SteamIDv3";
                case EInputTypes.SteamID64:
                case EInputTypes.ProfileUrl:
                    return "SteamID64";
                case EInputTypes.CommunityUrl:
                    return "Community URL";
                case EInputTypes.Vanity:
                    return "Vanity Name";
                case EInputTypes.Invalid:
                    return "Invalid";
                default:
                    return "";
            }
        })();
        setLabel("Detecting: " + label);
        setState(ENotificationState.Info);
    }

    const information = () => {

        const width = inputEl.current ? (inputEl.current as any).offsetWidth : "auto";

        // TODO: clean this up
        let className = "hidden";
        switch (state) {
            case ENotificationState.Success:
                className = "success";
                // Set timer to fade after 2 seconds
                setTimeout(() => {
                    setState(ENotificationState.None);
                }, 2000);
                break;
            case ENotificationState.Error:
                className = "error"

                setTimeout(() => {
                    setState(ENotificationState.None);
                }, 2000);
                break;
            case ENotificationState.Info:
                className = "";
                break;
            case ENotificationState.None:
            default:
                className = "hidden";
                break;
        }


        return (
            <div className={`information ${className}`} style={{ width }}>
                <span>{label}</span>
            </div>
        );
    }

    return (
        <form className="Input" onSubmit={handleSubmit}>
            <input id="search" style={{ color: "black" }} className={`${label ? "active" : ""}`} ref={inputEl} onChange={updateGuess} autoComplete="off" type="text" placeholder="SteamID, URL, or Vanity Name" name="id" />
            {information()}
            <button aria-label="Submit">
                <i className="fas fa-search"></i>
                <FaSearch />
            </button>
        </form>
    );
};

export default Input;
