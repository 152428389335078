import * as React from "react";

import "./shared.css"
import MediaQuery from "react-responsive";

const Page = (props: { header?: string, children?: React.ReactNode }) => {

    const header = () => {
        if (props.header) {
            return (
                <div className="header">
                    {props.header}
                </div>
            );
        }
        return null;
    }

    return (
        <div className="page">
            {header()}
            <MediaQuery query="(max-width: 900px)">
                {(matches) => {
                    if (matches) {
                        return <div className="container-mobile">
                            {props.children}
                        </div>

                    }
                    else {
                        return <div className="container">
                            {props.children}
                        </div>
                    }
                }}
            </MediaQuery>

        </div>
    );
};

export default Page;
