import * as React from "react";

import applicationState from "../../store/state";
import AccountPreview from "../shared/AccountPreview";

import "./banned.css"
import Page from "../shared/Page";

import useSWR from "swr";

const PAGE_LIMIT = 3 * 20;

const Banned = () => {
    const [totalPages, setTotalPages] = React.useState(1);

    const VirtualPage = ({ index }: { index: number }) => {
        const { data } = useSWR(`/api/banned/?count=${PAGE_LIMIT}&page=${index - 1}`, () => applicationState.getBanned(PAGE_LIMIT, index));
        if (!data) return null;
        return (<div className="flex flex-wrap">
            {data.map(account => (
                <div key={account.id} className="w-1/3">
                    <AccountPreview account={account} />
                </div>
            ))}
        </div>);
    }

    const pages = [];
    for (let i = 0; i < totalPages; i++) {
        pages.push(<VirtualPage index={i} key={i} />);
    }

    return (
        <Page header="Latest Banned Accounts">
            <div>
                {pages}
            </div>
            <div className="flex justify-center items-center h-10">
                <div className="text-xl hover:cursor-pointer" onClick={() => setTotalPages(totalPages + 1)}>
                    Load more results
                </div>
            </div>
        </Page>
    );

};

export default Banned;
