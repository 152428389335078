// import Tippy from "@tippyjs/react";
import * as React from "react";


export const CopyToClipboard: React.FC<{ text: string }> = (props) => {
    const text = props.text;

    const copyToClipboard = () => {
        var textField = document.createElement('textarea')
        textField.innerText = text;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    return (
        <div>
            {/* <Tippy content={"Click to copy to clipboard"} placement={"right"}> */}
                <span className="copyToClipboard" onClick={copyToClipboard}>{text}</span>
            {/* </Tippy> */}
        </div>
    );
}