import * as React from "react";

import "./about.css";
import Page from "../shared/Page";
import AboutBlurb from "./AboutBlurb";


const About: React.FC = (props) => {

    return (
        <Page header={"About"}>

            <AboutBlurb />

            <p>Issue tracking and feature requests: <a href="https://github.com/sbuggay/vaclist/issues">https://github.com/sbuggay/vaclist/issues</a></p>

            <h2 className="text-xl">Contact</h2>
            <p>
                You can reach me at sbuggay [at] gmail.com.
                <br></br>
                <a href="/privacy">The privacy policy can be found here.</a>
            </p>

            <h2 className="text-xl">Reference</h2>
            <ul className="list-disc" style={{ wordWrap: "break-word" }}>
                <li><a href="https://steamcommunity.com/dev">https://steamcommunity.com/dev</a></li>
                <li><a href="https://developer.valvesoftware.com/wiki/Steam_Web_API#GetPlayerSummaries_.28v0002.29">https://developer.valvesoftware.com/wiki/Steam_Web_API#GetPlayerSummaries_.28v0002.29</a></li>
                <li><a href="https://developer.valvesoftware.com/wiki/Steam_Web_API#GetPlayerBans_.28v1.29">https://developer.valvesoftware.com/wiki/Steam_Web_API#GetPlayerBans_.28v1.29</a></li>
            </ul>
            <br></br>
            {/* eslint-disable-next-line */}
            <a href='https://ko-fi.com/N4N56QVLQ' target='_blank'><img height='36' style={{ border: '0px', height: '36px' }} src='https://storage.ko-fi.com/cdn/kofi2.png?v=3' alt='Buy Me a Coffee at ko-fi.com' /></a>        </Page>
    );
}

export default About;