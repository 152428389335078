import * as React from "react";
import useSWR from 'swr';

import applicationState from "../../store/state";
import AccountPreview from "./AccountPreview";

import "./shared.css"
import MediaQuery from "react-responsive";

const ShortList: React.FC = (props) => {

    const { data } = useSWR('/api/recent', () => applicationState.getRecent());

    if (!data) return null;
    const [last_checked_accounts, last_banned_accounts] = data;

    const list = (ids: string[]) => {
        return ids.map((id, idx) => {
            const account = applicationState.accounts[id];
            return (
                <AccountPreview key={idx} account={account} />
            );
        });
    }


    return (

        <MediaQuery query="(max-width: 900px)">
            {(matches) => {
                const className = `shortList ${matches ? "mobile" : ""}`;

                return (
                    <div className={className}>
                        <div style={{ marginRight: "10px" }}>
                            <div className="shortList-header">Latest Indexed Accounts</div>
                            {list(last_checked_accounts)}
                        </div>
                        <div>
                            <div className="shortList-header">Latest Banned Accounts</div>
                            {list(last_banned_accounts)}
                        </div>
                    </div>
                );
            }}
        </MediaQuery>

    );
};

export default ShortList;
