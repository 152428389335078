
import * as React from "react";
import { profileContext } from "../../store/state";
import Dropdown from "../utilities/Dropdown";
import LoginButton from "./LoginButton";

const LoginControl = () => {

    const context = React.useContext(profileContext);

    // TODO: for now this control can only redirect. Should be able to run arbitrary code.
    const profileOptions = {
        "Profile": `/account/${context.profile ? context.profile.steam_id : ""}`,
        "Settings": "/settings",
        "Logout": `/steam/logout?redirect=${encodeURIComponent(window.location.pathname)}`
    }

    if (context.isLoading) {
        return null;
    }

    if (context.profile) {
        return (
            <div style={{ marginLeft: "20px" }}>
                <Dropdown options={profileOptions}>
                    <img alt="avatar" className="avatar" style={{ width: "30px", height: "30px" }} src={context.profile.avatar} />
                </Dropdown>
            </div>
        );
    }
    else {
        return <LoginButton />
    }

};

export default LoginControl;