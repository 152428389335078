import * as React from 'react';
// import { withRouter, RouteComponentProps } from 'react-router-dom';

import { format } from "date-fns";
import MediaQuery from 'react-responsive';
import Bans from '../account/Bans';
import { useNavigate } from 'react-router-dom';
import { Ghost } from '../account/Account';

// interface IProps extends RouteComponentProps<any> {
//     account: IAccount;
// }

export const GhostAccountRow = () => {
    return (
        <tr>

            <td>
                <Ghost width={'100%'} />
            </td>
            <MediaQuery query="(max-width: 900px)" style={{ width: "300px" }}>
                {(matches) => {
                    if (!matches) {
                        return (
                            <td>
                                <Ghost width={'100%'} />
                            </td>
                        );
                    }
                    else {
                        return null;
                    }
                }}
            </MediaQuery>
            <td style={{ width: "100px" }}>
                <Ghost width={'100%'} />
            </td>
        </tr>
    );
}

export const AccountRow = (props: any) => {
    const navigate = useNavigate();

    const account = props.account;

    const timeCreated = account.timecreated !== "0"
        ? format(new Date(parseInt(account.timecreated) * 1000), "MM/DD/YYYY")
        : "Private";

    return (
        <tr onClick={() => navigate(`/account/${account.steam_id}`)}>
            <td>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img alt="avatar" src={account.avatar} />
                    <div>
                        <div>{account.personaname}</div>
                        <div style={{ fontSize: "0.7rem" }}>
                            <a className='underline' style={{ color: "#999" }} onClick={e => e.stopPropagation()} href={account.profile_url} >{account.steam_id}</a>
                        </div>
                    </div>
                </div>
            </td>
            <MediaQuery query="(max-width: 900px)" style={{ width: "300px" }}>
                {(matches) => {

                    const timeTracked = account.timetracked ? format(new Date(parseInt(account.timetracked)), "MM/DD/YYYY") : "";

                    if (!matches) {
                        return (
                            <td>
                                <div>{timeCreated}</div>
                                <div style={{ fontSize: "0.7rem", color: "#999" }}>Tracked: {timeTracked}</div>
                            </td>
                        );
                    }
                    else {
                        return null;
                    }
                }}
            </MediaQuery>
            <td style={{ width: "100px" }}>
                <Bans account={account} />
            </td>
        </tr>
    );

};
