import * as React from "react";
import Page from "./shared/Page";


interface IChange {
    title?: string;
    date: Date;
    text: JSX.Element;
}

const changelog: IChange[] = [
    {
        date: new Date("8/20/2023"),
        text: (
            <div>
                <ul className="list-disc">
                    <li>Lots of small style improvements and changes.</li>
                    <li>More efficient indexing backend.</li>
                    <li>Added additional data to the stats page.</li>
                </ul>
            </div>
        )
    },
    {
        date: new Date("4/17/2022"),
        text: (
            <div>
                <ul className="list-disc">
                    <li>Now behind Cloudflare.</li>
                    <li>"Show banned" list now sorts by banned date, most recent being at the top.</li>
                    <li>Various bug fixes involving searching for names with numbers in them (https://github.com/sbuggay/vaclist-issues/issues/8).</li>
                </ul>
            </div>
        )
    },
    {
        date: new Date("1/27/2020"),
        text: (
            <div>
                <ul className="list-disc">
                    <li>Added banned account age histogram on the stats page.</li>
                    <li>General interface improvements.</li>
                    <li>Added the ability to filter your tracked accounts to only show banned ones.</li>
                    <li>Fixed user tracking accuracy percentage.</li>
                    <li>Server infrastructure improvements. Mostly around account reindexing.</li>
                </ul>
            </div >
        )
    },
    {
        date: new Date("1/2/2020"),
        text: (
            <div>
                Happy new year!
                <ul className="list-disc">
                    <li>Logging in (and out) will return you to the page you started the process from.</li>
                    <li>Added links to header.</li>
                    <li>Fixed some indexing issues.</li>
                </ul>
            </div >
        )
    },
    {
        date: new Date("12/31/2019"),
        text: (
            <div>
                <ul className="list-disc">
                    <li>Added a changelog.</li>
                    <li>Reduced time to reindex accounts from seven days to one day.</li>
                    <li>Improved perceived loading on the account view and stats page.</li>
                    <li>General user interface improvements.</li>
                    <li>Initial backend work for notifications.</li>
                    <li>Add reset date to stats page.</li>
                </ul>
            </div >
        )
    }
];

const changelogElems = () => {
    return changelog.map((change) => {
        return (
            <div>
                <h1 className="text-xl pt-4">{change.title ? change.title : `${change.date.toDateString()} Update`}</h1>
                <>
                    {change.text}
                </>
            </div>
        );
    });
}

const Changelog: React.FC = () => {
    return (
        <Page header="Changelog">
            {changelogElems()}
        </Page>
    );
};

export default Changelog;