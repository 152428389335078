import * as React from "react";
import state from "../../store/state";
import Page from "../shared/Page";

const FeatureRow = (props: { title: string, description?: string, children?: React.ReactNode }) => {
    return <tr>
        <td>{props.title}</td>
        <td>{props.description}</td>
        <td>{props.children}</td>
    </tr>;
}

const Beta: React.FC = () => {
    return <>
        <Page header="Beta Features">
            <p>"Secret" page to test beta features against production. Use at your own risk.</p>
            <table style={{ width: "100%" }}>
                <FeatureRow title="Banned" description="Show most recently banned accounts."><a href="/banned">/banned</a></FeatureRow>
                <FeatureRow title="Batch Track" description="Tool to track up to 100 accounts at once."><a href="/batch">/batch</a></FeatureRow>
                <FeatureRow title="Untrack All" description="Untracks all accounts."><button onClick={() => state.untrackAll()}>Untrack All</button></FeatureRow>
            </table>
        </Page>
    </>;
};

export default Beta;
