import Tippy from '@tippyjs/react';
import * as React from 'react';
import { FaGamepad, FaShieldAlt, FaHandshakeSlash } from 'react-icons/fa';
import { IAccount } from '../../../../server/db/Account';
import { trackedbeforeBan } from '../../store/state';

export const RedBan = "#f71735";
export const YelloWarning = "yellow";

const Bans: React.FC<{ account: IAccount }> = (props) => {
    const account = props.account;

    const activeColor = trackedbeforeBan(account)
        ? RedBan
        : YelloWarning;
    const inactiveColor = "#999";

    const generateTooltip = (bans: number, type: string): string => {
        if (bans > 0) {
            return `${bans} ${type} ban${bans === 1 ? "" : "s"} on record`;
        }
        else {
            return `no ${type} bans on record`;
        }
    }

    return (
        <div className="flex gap-2" style={{ float: "right" }}>
            <Tippy content={generateTooltip(account.game_bans, "game")}>
                <span>
                    <FaGamepad style={{ color: account.game_bans > 0 ? activeColor : inactiveColor }} />
                </span>
            </Tippy>
            <Tippy content={generateTooltip(account.vac_bans ? 1 : 0, "VAC")}>
                <span>
                    <FaShieldAlt style={{ color: account.vac_bans ? activeColor : inactiveColor }} />
                </span>
            </Tippy>
            <Tippy content={account.economy_ban === "none" ? "no economy bans on record" : account.economy_ban}>
                <span>
                    <FaHandshakeSlash style={{ color: account.economy_ban !== "none" ? activeColor : inactiveColor }} />
                </span>
            </Tippy>
        </div>
    );

}

export default Bans;