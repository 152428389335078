// import { observable, computed } from 'mobx';
import { IAccount } from '../../../server/db/Account'
import { addDays } from 'date-fns';

import { createContext } from 'react';
import { KeyedMutator, mutate } from 'swr';


export enum EResolution {
    None,
    Found,
    NotFound,
    Invalid
}

export interface IAccountResolution {
    resolution: EResolution;
    account?: IAccount;
}

export function trackedbeforeBan(account: IAccount) {
    if (!account.timetracked) return false;
    return addDays(parseInt(account.last_checked.toString()), -1 * account.last_ban) > new Date(parseInt(account.timetracked));
}

export enum ETrackedAccountsState {
    None,
    Loading,
    Success,
    Failure
}

interface IProfileContext {
    profile: IAccount | null | undefined;
    trackedIds: Set<string> | null | undefined;
    refreshTrackedIds: KeyedMutator<Set<string>> | null | undefined;
    isAdmin: boolean | null | undefined;
    isLoading: boolean;
}

export const profileContext = createContext<IProfileContext>({
    profile: null,
    trackedIds: null,
    refreshTrackedIds: undefined,
    isAdmin: false,
    isLoading: false,
});

export class ApplicationState {
    public profile: IAccount | null;
    // public loggedIn: boolean;
    public accounts: { [id: string]: IAccount };
    // public tracked_accounts: string[];
    // public page: number;
    // public pageSize: number;
    public showBanned: boolean;
    // public server: any;
    // public trackChanging: boolean;

    constructor() {
        this.profile = null;
        // this.loggedIn = false;
        this.accounts = {};
        // this.tracked_accounts = [];
        // this.most_tracked_accounts = [];
        // this.page = 0;
        // this.pageSize = 15;
        this.showBanned = false;
        // this.server = {} // admin server state
        // this.trackChanging = false;
        // this.trackedAccountsState = ETrackedAccountsState.None;
    }



    // get trackedAccounts() {
    //     const accounts = this.tracked_accounts.filter(id => {
    //         return this.showBanned ?
    //             (this.accounts[id].vac_bans > 0 || this.accounts[id].game_bans > 0 || this.accounts[id].economy_ban !== 'none') :
    //             true;
    //     });

    //     if (this.showBanned) {
    //         accounts.sort((a, b) => {
    //             return this.accounts[a].last_ban - this.accounts[b].last_ban
    //         });
    //     }

    //     return accounts;
    // }

    // public setPage(page: number) {
    //     this.page = page;
    // }

    public async getProfile() {
        const response = await fetch('/steam/profile');
        const json = await response.json() as IAccount;
        this.profile = json;
        // this.loggedIn = true;
        // await this.getTrackedAccounts();
        return json;
    }

    public async getAccount(id: string): Promise<IAccountResolution> {

        // If we don't get a number, don't even try to resolve it.
        if (isNaN(parseInt(id))) {
            return {
                resolution: EResolution.Invalid
            }
        }

        if (this.accounts[id]) {
            return {
                resolution: EResolution.Found,
                account: this.accounts[id]
            }
        }

        const response = await fetch(`/api/account?q=${id}`);
        if (response.status === 200) {
            const json = await response.json();
            this.accounts[id] = json;

            return {
                resolution: EResolution.Found,
                account: json
            }
        };

        return {
            resolution: EResolution.NotFound
        }
    }

    public async getTrackedAccounts(limit = 20, page = 0, banned = false) {
        // this.trackedAccountsState = ETrackedAccountsState.Loading;
        const res = await fetch(`/api/trackedAccounts?limit=${limit}&page=${page}&banned=${banned}`);
        performance.mark('getTrackedAccounts()');
        const json = await res.json() as IAccount[];

        json.forEach(account => {
            mutate(`/api/account?q=${account.id}`, account, {
                revalidate: false,
                populateCache: true,
            });
        });

        return json;
    }

    public async trackPlayer(id: number | undefined): Promise<boolean> {
        if (!this.profile || !id) return false;

        return fetch('/api/trackPlayer', {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify({ track: id })
        }).then(result => {
            if (result.status === 200) {
                this.getTrackedAccounts();
            }
            return false;
        });
    }

    public async untrackPlayer(id: number | undefined): Promise<boolean> {
        if (!this.profile || !id) return false;

        return fetch('/api/untrackPlayer', {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify({ untrack: id })
        }).then(result => {
            if (result.status === 200) {
                this.getTrackedAccounts();
            }
            return false;
        });
    }

    public async trackBatch(steamids: string[]): Promise<boolean> {
        if (!this.profile || steamids.length <= 0) return false;

        return fetch('/api/trackBatch', {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify({ batch: steamids })
        }).then(result => {
            if (result.status === 200) {
                this.getTrackedAccounts();
                return true;
            }
            return false;
        });
    }

    public async untrackAll(): Promise<boolean> {
        if (!this.profile) return false;

        if (!window.confirm('Untrack all accounts?')) return false;

        return fetch('/api/untrackAll', {
            method: 'POST'
        }).then(result => {
            this.getTrackedAccounts();
            if (result.status === 200) {
                return true;
            }
            else {
                return false;
            }
        });
    }

    private registerAccounts(accounts: IAccount[]) {
        accounts.forEach(account => {
            this.accounts[account.steam_id] = account;
        });
    }

    public async getRecent() {
        const res = await fetch('/api/recent');
        const json = await res.json() as IAccount[][];
        const [checked, banned] = json;

        this.registerAccounts(checked);
        this.registerAccounts(banned);

        const id = ((account: IAccount) => account.steam_id);

        return [checked.map(id), banned.map(id)];
    }

    public async getBanned(count: number = 20, page: number = 0) {
        const res = await fetch(`/api/banned?count=${count}&page=${page}`);
        const json = await res.json() as IAccount[];
        return json.reverse();
    }

    // public percentBanned() {
    //     const accounts = this.tracked_accounts.map(id => this.accounts[id])
    //     const trackedBeforeBanned = accounts.filter(account => account.vac_bans > 0 || account.game_bans > 0).filter(account => trackedbeforeBan(account));
    //     return trackedBeforeBanned.length / accounts.length * 100;
    // }

    // public toggleShowBanned() {
    //     this.page = 0;
    //     this.showBanned = !this.showBanned;
    // }


    public async getNote(id?: number) {
        if (id === undefined) return;

        const res = await fetch(`/api/note?id=${id}`);
        const json = await res.json() as string;
        return json;
    }
}

const instance = new ApplicationState();

export default instance;