import * as React from "react";
import ShortList from "./shared/ShortList";
import AboutBlurb from "./about/AboutBlurb";
import Page from "./shared/Page";
import GameShowcase from "./about/GameShowcase";
import MediaQuery from "react-responsive";

const Intro: React.FC = () => {
    return (
        <>
            <Page>
                <AboutBlurb />
                <MediaQuery query="(max-width: 900px)">
                    {(matches) => {
                        return matches ? null : <GameShowcase />
                    }}
                </MediaQuery>
                <ShortList />
            </Page>
        </>
    );
};

export default Intro;