import * as React from "react";
import * as ReactDOMClient from "react-dom/client";
import "./index.css";
import { unregister } from "./registerServiceWorker";
import App from "./components/App";

const container = document.getElementById('root');

if (!container) {
	throw new Error('Can\'t find root element');
}

const root = ReactDOMClient.createRoot(container);

root.render(<App />);

unregister();
