import Tippy from "@tippyjs/react";
import { useContext } from "react";
import { profileContext } from "src/store/state";
import { FaBell } from 'react-icons/fa';

export const Notifications = () => {

    const profile = useContext(profileContext);

    if (!profile.profile) return null;

    const notifications = [];

    if (notifications.length === 0) {
        return (
            <div style={{ marginLeft: "20px" }}>
                <Tippy content={"No notifications"}>
                    <span>
                        <FaBell className="text-xl" />
                    </span>
                </Tippy>
            </div>
        );
    }

    return (
        <div style={{ marginLeft: "20px" }}>
            <FaBell className="text-xl" />
        </div>
    );
}