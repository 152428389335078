import { Cache } from "../storage/Cache";
import { toSteamID64, guessInput, EInputTypes } from "../utilities/steamid";
import { ISummary } from "../../../server/steam/steam";
import { IBan } from "../../../server/steam/steam";

const API_ENDPOINT = "/api";

export interface ISummaries {
    response: {
        players: ISummary[]
    }
}

export default class SteamApi {
    public static async getPlayerSummaries(ids: string[]): Promise<{ players: ISummaries[] }> {
        if (ids.length === 0) {
            return Promise.resolve({ players: [] });
        }
        const apiUrl = `${API_ENDPOINT}/getPlayerSummaries?steamids=${ids.join(",")}`;
        const cachedItem = Cache.getItem(apiUrl);
        if (cachedItem) {
            return Promise.resolve(cachedItem);
        }
        return fetch(apiUrl).then(res => res.json()).then(json => json.response).then(response => {
            Cache.setItem(apiUrl, response, 60000);
            return response;
        });
    }

    public static async getPlayerBans(ids: string[]): Promise<{ players: IBan[] }> {
        if (ids.length === 0) {
            return Promise.resolve({ players: [] });
        }

        const apiUrl = `${API_ENDPOINT}/getPlayerBans?steamids=${ids.join(",")}`;
        const cachedItem = Cache.getItem(apiUrl);
        if (cachedItem) {
            return Promise.resolve(cachedItem);
        }
        return fetch(apiUrl).then(res => res.json()).then(response => {
            Cache.setItem(apiUrl, response, 60000);
            return response;
        });
    }

    public static async resolveVanityURL(vanity: string) {
        const apiUrl = `${API_ENDPOINT}/resolveVanityURL?vanityurl=${vanity}`;
        return fetch(apiUrl).then(res => res.json()).then(response => {
            return response;
        });
    }

    public static async search(query: string): Promise<string> {

        const lastPath = (url: string) => url.split("/").filter(s => s !== "").pop();

        const guess = guessInput(query);
        switch (guess) {
            case EInputTypes.SteamID:
                return Promise.resolve(toSteamID64(query));
            case EInputTypes.SteamID3:
                return Promise.resolve(toSteamID64(query));
            case EInputTypes.SteamID64:
                return Promise.resolve(query);
            case EInputTypes.CommunityUrl:
                return fetch(`${API_ENDPOINT}/search?q=${lastPath(query)}`).then(res => res.json()).then(res => {
                    return res.response.steamid;
                });
            case EInputTypes.ProfileUrl:
                return Promise.resolve(lastPath(query) || ""); // TODO:" verify this...
            case EInputTypes.Vanity:
                return fetch(`${API_ENDPOINT}/search?q=${query}`).then(res => {
                    return res.json();
                }).then(res => {
                    if (res.success === 42) { // Steam API code for no match lol
                        return "";
                    }
                    return res.response.steamid;
                });
            default:
                return Promise.resolve("");
        }
    }
}