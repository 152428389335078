import * as React from "react";
import Input from "./Input";

import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import LoginControl from "./LoginControl";

import "./header.css";
import { Notifications } from "./Notifications";

export const useWindowEvent = (event: any, callback: any) => {
    React.useEffect(() => {
        window.addEventListener(event, callback);
        return () => window.removeEventListener(event, callback);
    }, [event, callback]);
};

export const useGlobalScrollEvent = (callback: any) => {
    return useWindowEvent("scroll", callback);
};

const Header: React.FC = (props) => {

    const [sticky, setSticky] = React.useState(false);

    useGlobalScrollEvent((e: any) => {
        const headerHeight = 0;

        if (window.scrollY > headerHeight) {
            setSticky(true);
        }
        else {
            setSticky(false);
        }

    });

    return (
        <MediaQuery query="(max-width: 900px)">
            {(matches) => {
                if (matches) {
                    return (
                        <header className="Header">
                            <div className="container">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}>
                                    <Link style={{ textDecoration: "none" }} to="/"><div className="text-xl">VacList</div></Link>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <LoginControl />
                                    </div>
                                </div>
                                <div >
                                    <Input />
                                </div>
                            </div>
                        </header>
                    );
                } else {
                    return (
                        <>
                            {sticky ? <div style={{ height: "56px" }}></div> : null}
                            <header className={`Header${sticky ? " sticky" : ""}`} >
                                <div className="container" style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                    <div className='flex gap-4 items-center mr-4'>
                                        <Link style={{ textDecoration: "none" }} to="/"><div className="text-xl">VacList</div></Link>
                                        <Link className={"link"} style={{ textDecoration: "none" }} to="/stats">stats</Link>
                                        <Link className={"link"} style={{ textDecoration: "none" }} to="/banned">banned</Link>
                                    </div>
                                    <Input />
                                    <Notifications />
                                    <LoginControl />
                                </div>
                            </header>
                        </>
                    );
                }
            }}
        </MediaQuery>
    );
};

export default Header;