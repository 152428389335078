
const BASE_NUM = 76561197960265728n;
const REGEX_STEAMID64 = /^[0-9]{17}$/;
const REGEX_STEAMID = /^STEAM_[0-5]:[01]:\d+$/;
const REGEX_STEAMID3 = /^\[U:1:[0-9]+\]$/;

/**
 * Generate a SteamID64 from a SteamID or SteamID3
 */
export function toSteamID64(steamid: string) {
    if (isSteamID64(steamid)) return steamid;

    if (isSteamID3(steamid)) {
        steamid = fromSteamID3(steamid);
    }
    else if (!isSteamID(steamid)) {
        throw new TypeError("Parameter must be a SteamID (e.g. STEAM_0:1:912783)");
    }

    var split = steamid.split(":"),
        v = BASE_NUM,
        z = BigInt(split[2]),
        y = BigInt(split[1]);

    if (z) {
        return (v + (z * 2n + y)).toString();
    }

    return "";
}

/**
 * Generate a SteamID from a SteamID64 or SteamID3
 */
export function toSteamID(steamid: string) {
    if (isSteamID(steamid)) return steamid;

    if (isSteamID3(steamid)) {
        return fromSteamID3(steamid);
    }
    else if (!isSteamID64(steamid)) {
        throw new TypeError("Parameter must be a SteamID64 (e.g. 76561190000000000)");
    }

    var v = BASE_NUM,
        w = BigInt(steamid),
        y = w % 2n;

    w = w - y - v;
    return "STEAM_0:" + y + ":" + (w / 2n).toString();
}

/**
 * Generate a SteamID3 from a SteamID or SteamID64
 */
export function toSteamID3(steamid: string) {
    if (!isSteamID(steamid)) {
        steamid = toSteamID(steamid);
    }

    var split = steamid.split(":");

    return "[U:1:" + (parseInt(split[1]) + parseInt(split[2]) * 2) + "]";
}

/**
 * Generate a SteamID from a SteamID3.
 */
export function fromSteamID3(steamid3: string) {
    var split = steamid3.split(":");
    var last = BigInt(split[2].substring(0, split[2].length - 1));

    return "STEAM_0:" + (last % 2n + ":" + Math.floor(Number(last / 2n)));
}

// ------------------------------------------------------------------------------

export function isSteamID(id: string) {
    return REGEX_STEAMID.test(id);
}

export function isSteamID64(id: string) {
    return REGEX_STEAMID64.test(id);
}

export function isSteamID3(id: string) {
    return REGEX_STEAMID3.test(id);
}

export function profileURL(steamid64: string) {
    if (!isSteamID64(steamid64)) {
        steamid64 = toSteamID64(steamid64) || "";
    }
    return "http://steamcommunity.com/profiles/" + steamid64;
}

export enum EInputTypes {
    SteamID,
    SteamID3,
    SteamID64,
    CommunityUrl,
    ProfileUrl,
    Vanity,
    Invalid
}

const regexp = /^[a-zA-Z0-9-_]+$/;

export function guessInput(query: string): EInputTypes {
    const VANITY_URL = new RegExp(/https:\/\/steamcommunity.com\/id/gi);
    const PROFILE_URL = new RegExp(/https:\/\/steamcommunity.com\/profiles/gi);
    if (query.match(VANITY_URL)) {
        return EInputTypes.CommunityUrl;
    }
    else if (query.match(PROFILE_URL)) {
        return EInputTypes.ProfileUrl;
    }
    else if (isSteamID(query)) {
        return EInputTypes.SteamID;
    }
    else if (isSteamID3(query)) {
        return EInputTypes.SteamID3;
    }
    else if (isSteamID64(query)) {
        return EInputTypes.SteamID64;
    }
    else if (regexp.test(query)) {
        return EInputTypes.Vanity;
    }
    else {
        return EInputTypes.Invalid;
    }
}