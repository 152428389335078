import * as React from "react";

const appIds = [570, 730, 10, 440, 629760, 252490];

const GameShowcase: React.FC = (props) => {
    return (
        <>
            <p>A few of the most popular games using VAC:</p>
            <div id="showcase">
                {appIds.map(id => {
                    return (
                        <div key={id}>
                            <a href={`https://store.steampowered.com/app/${id}`}><img alt={`${id}`}
                                                                                      src={`https://cdn.akamai.steamstatic.com/steam/apps/${id}/header.jpg`}/></a>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default GameShowcase;

