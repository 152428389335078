
interface ICachedItem {
    data: any;
    expiry?: number;
}

export class Cache {

    static setItem(key: string, data: any, expireIn?: number): void {
        const cachedItem: ICachedItem = { data };

        if (expireIn) {
            cachedItem.expiry = Date.now() + expireIn;
        }

        localStorage.setItem(key, JSON.stringify(cachedItem));
    }

    static getItem(key: string): any {
        const item = localStorage.getItem(key);
        if (item) {
            const cachedItem = JSON.parse(item) as ICachedItem;
            if (cachedItem.expiry && (Date.now() > cachedItem.expiry)) {
                localStorage.removeItem(key);
                return null;
            }
            return cachedItem.data;
        }
        return null;
    }
}