import * as React from "react";
import state, { EResolution, profileContext } from "../../store/state";
import { format, distanceInWordsToNow, addDays } from "date-fns";
import { toSteamID3, toSteamID } from "../../utilities/steamid";
import { CopyToClipboard } from "../utilities/CopyToClipboard";
import MediaQuery from "react-responsive";
import useSWR from 'swr';
import { FaDatabase } from 'react-icons/fa';

import "./account.css"
import Tippy from "@tippyjs/react";
import Notes from "./Notes";
import { useParams } from "react-router-dom";

enum EAccountDiscovery {
    Cache,
    Database,
    Steam,
    Local
}

const TopLeft = (props: { children?: React.ReactNode }) => {
    return <MediaQuery minWidth={900}>
        {(matches) => {
            return <div style={{ minWidth: matches ? "200px" : "150px", backgroundColor: "#19202c" }}>
                <div style={{ padding: "16px", float: "right" }}>
                    <div style={{ paddingBottom: "16px" }}>
                        {props.children}
                    </div>
                </div>
            </div>
        }}
    </MediaQuery>
}

const TopRight = (props: { children?: React.ReactNode }) => {
    return (
        <div style={{ width: "100%", padding: "16px" }}>
            {props.children}
        </div>
    );
}

const BottomLeft = (props: { children?: React.ReactNode }) => {
    return <MediaQuery minWidth={900}>
        {(matches) => {
            return (
                <div style={{ minWidth: matches ? "200px" : "150px", backgroundColor: "#19202c", paddingBottom: "16px" }}>
                    {props.children}
                </div>
            );
        }}
    </MediaQuery>
}

const BottomRight = (props: { children?: React.ReactNode }) => {
    return (
        <div className="bottomRight">
            {props.children}
        </div>
    );
}

/**
 * Functional component for ghost elements during loading
 *
 * @param {*} props
 * @returns
 */
export const Ghost = (props: { width: string }) => {
    const { width } = props;

    return (
        <div style={{ width: width, height: "24px" }}>
            <div className="ghost-loading" style={{ width: "90%", height: "90%", borderRadius: "6px" }}></div>
        </div >
    )
}

const Account = () => {
    const { id } = useParams();
    const { profile, trackedIds, refreshTrackedIds } = React.useContext(profileContext);

    const { data, error, isLoading } = useSWR(`/api/account?q=${id}`, () => state.getAccount(id || ''));
    const account = data?.account;
    const { data: noteData, isLoading: isNoteLoading, error: noteError } = useSWR(account ? `api/note?id=${account.id}` : null, () => state.getNote(account?.id));

    const [tracked, setTracked] = React.useState(trackedIds?.has(account?.steam_id || ''));

    React.useEffect(() => {
        setTracked(trackedIds?.has(account?.steam_id || ''));
    }, [trackedIds, account?.steam_id]);

    function handleSave(content?: string) {
        fetch(`/api/note`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                forId: account?.id,
                content
            })
        });
    }

    if (data) {
        if (data.resolution === EResolution.NotFound) {
            return (
                <div style={{ backgroundColor: "#202938", width: "100%", height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <div>No account found with that Steam ID.</div>
                    </div>
                </div>
            );
        }
        else if (error || data.resolution === EResolution.Invalid) {
            return (
                <div style={{ backgroundColor: "#202938", width: "100%", height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <div>There was an error with your account query.</div>
                    </div>
                </div>
            );
        }

    }

    if (isLoading) {
        return (
            <div style={{ backgroundColor: "#202938", width: "100%" }}>
                <div style={{ height: "100%", display: "flex", flexFlow: "column" }}>
                    <div style={{ display: "flex", alignItems: "stretch", height: "100px" }}>
                        <TopLeft>
                            <div style={{ backgroundColor: "white", width: "64px", height: "64px" }}></div>
                        </TopLeft>
                        <TopRight>
                            <Ghost width={'500px'} />
                            <Ghost width={'100px'} />
                        </TopRight>
                    </div >
                    <div style={{ display: "flex", alignItems: "stretch", flexGrow: 1 }}>
                        <BottomLeft>
                            <div style={{ color: "#aaa", float: "right", paddingRight: "16px", textAlign: "right" }}>
                                <div>Status</div>
                                <div>Created</div>
                                <br />
                                <div>SteamID 64</div>
                                <div>SteamID v3</div>
                                <div>SteamID v1</div>
                                <br />
                                <div>Game Ban</div>
                                <div>VAC Ban</div>
                                <div>Economy Ban</div>
                                <br />
                                <div>Last Checked</div>
                            </div>
                        </BottomLeft>
                        <BottomRight>
                            <Ghost width={'100px'} />
                            <Ghost width={'100px'} />
                            <br />
                            <Ghost width={'150px'} />
                            <Ghost width={'150px'} />
                            <Ghost width={'150px'} />
                            <br />
                            <Ghost width={'100px'} />
                            <Ghost width={'100px'} />
                            <Ghost width={'100px'} />
                            <br />
                            <Ghost width={'100px'} />
                        </BottomRight>
                    </div>
                </div>
            </div>
        );
    }

    const trackButton = () => {
        // If we aren't logged in, at least show them they can log in to track

        const buttonStyle = {
            fontSize: "14px",
            border: "none",
            color: "white",
            padding: "5px 10px",
            borderRadius: "3px",
            height: "30px",
        };

        if (!state.profile) {
            const colorStyle = { color: "gray", backgroundColor: "white" };
            return <button style={{ ...buttonStyle, ...colorStyle }}>Log in to track</button>;
        }

        if (!account) return;

        let label = tracked ? "Remove" : "Add";
        let action = tracked ?
            () => {
                state.untrackPlayer(data.account ? data.account.id : 0)
                setTracked(false);
                refreshTrackedIds!();
            }
            :
            () => {
                state.trackPlayer(data.account ? data.account.id : 0)
                setTracked(true);
                refreshTrackedIds!();
            };

        let colorStyle = tracked ? { color: "black", backgroundColor: "white" } : { backgroundImage: "linear-gradient(rgb(121, 153, 5) 5%, rgb(83, 105, 4) 95%)" };
        return <button style={{ ...buttonStyle, ...colorStyle, cursor: "pointer" }} onClick={action}>{label}</button>;
    }

    const gameBanDetail = (bans: number) => {
        if (bans === 0) {
            return "none";
        }
        else {
            return <span style={{ color: "#f71735" }}>{`${bans} game ban${bans === 1 ? "" : "s"} on record`}</span>
        }
    }

    const vacBanDetail = (bans: number) => {
        if (bans === 0) {
            return "none";
        }
        else {
            return <span style={{ color: "#f71735" }}>{`${bans} VAC ban${bans === 1 ? "" : "s"} on record`}</span>
        }
    }

    const economyBanDetail = (ban: string) => {
        return ban;
    }

    const lastBanDetail = (date: Date) => {
        return <div>{format(date, "MM/DD/YYYY")} <span style={{ color: "#aaa" }}>{distanceInWordsToNow(date)} ago</span></div>
    }

    const fetchedFrom = () => {
        if (!account || !account.meta) return null;
        //TODO: refactor this
        const label = (d: number) => {
            switch (d) {
                case EAccountDiscovery.Cache:
                    return "cache";
                case EAccountDiscovery.Database:
                    return "db";
                case EAccountDiscovery.Steam:
                    return "steam";
                default:
                    return "";
            }
        };
        const tooltip = (d: number) => {
            switch (d) {
                case EAccountDiscovery.Cache:
                    return "Resolved from VacList cache";
                case EAccountDiscovery.Database:
                    return "Resolved from VacList database";
                case EAccountDiscovery.Steam:
                    return "Resolved from Steam APIs";
                default:
                    return "";
            }
        };
        return (
            <div style={{ position: "absolute", bottom: 16, right: 16 }}>
                <Tippy content={tooltip(account.meta.discovery)} >
                    <div>
                        <span style={{ marginRight: "8px" }}>{label(account.meta.discovery)}</span><FaDatabase />
                    </div>
                </Tippy>
            </div>
        );
    }

    const date = () => {
        if (!account) return null;
        if (account.timecreated === 0n) {
            return <span>Private</span>
        }
        return (
            <div>
                {format(parseInt(account.timecreated.toString()) * 1000, "MM/DD/YYYY")}
                <span style={{ color: "#aaa" }}> <MediaQuery minWidth={900}>{distanceInWordsToNow(parseInt(account.timecreated.toString()) * 1000)} ago</MediaQuery></span>
            </div>
        )
    }

    const accountData = () => {
        if (account) {
            return (
                <div style={{ height: "100%", display: "flex", flexFlow: "column" }}>
                    <div style={{ display: "flex", alignItems: "stretch", height: "100px" }}>
                        <TopLeft>
                            <a href={account.profile_url}>
                                <img alt="avatar" style={{ margin: "-1px", border: "1px solid white" }} src={account.avatar}></img>
                            </a>
                        </TopLeft>
                        <TopRight>
                            <MediaQuery minWidth={900}>
                                {(matches) => {
                                    if (!data.account) return null;
                                    const link = `https://steamcommunity.com/profiles/${data.account.steam_id}`;

                                    if (matches) {
                                        return (
                                            <>
                                                <div style={{ float: "right" }}>
                                                    <div style={{
                                                        color: (() => {
                                                            if (isNoteLoading) return 'grey';
                                                            if (noteError) return 'red';
                                                            if (noteData) return 'white'
                                                        })(),
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "16px"
                                                    }}>
                                                        {profile && < Notes content={noteData} onSave={handleSave} />}
                                                        {trackButton()}
                                                    </div>
                                                </div>

                                                <div style={{ height: "64px", paddingBottom: "20px" }}>
                                                    <div style={{ fontSize: "1.5em" }}>{data.account.personaname}</div>
                                                    <div><a style={{ color: "#aaa" }} className="underline" href={link}>{data.account.profile_url}</a></div>
                                                </div>
                                            </>
                                        );
                                    }
                                    else {
                                        return (
                                            <>
                                                <div style={{ height: "64px", paddingBottom: "20px" }}>
                                                    <a style={{ color: "#aaa" }} href={link}><div style={{ fontSize: "1.5em" }}>{data.account.personaname}</div></a>
                                                    <div>{trackButton()}</div>
                                                </div>
                                            </>
                                        );
                                    }
                                }}
                            </MediaQuery>

                        </TopRight>
                    </div >
                    <div className="bottom">
                        <BottomLeft>
                            <div style={{ color: "#aaa", float: "right", paddingRight: "16px", textAlign: "right" }}>
                                <div>Status</div>
                                {account.communityvisibilitystate === 3 ? <div>Created</div> : null}
                                <br />
                                <div>SteamID 64</div>
                                <div>SteamID v3</div>
                                <div>SteamID v1</div>
                                <br />
                                <div>Game Ban</div>
                                <div>VAC Ban</div>
                                <div>Economy Ban</div>
                                <div>{account.last_ban ? "Last Ban" : null}</div>
                                <br />
                                <div>Last Checked</div>
                                <div>{account.tracked_by ? "Tracked By" : null}</div>
                            </div>
                        </BottomLeft>
                        <BottomRight>
                            <div>{account.communityvisibilitystate === 3 ? "Public" : "Private"}</div>
                            <div>{account.communityvisibilitystate === 3 ? date() : null}</div>
                            <br />
                            <CopyToClipboard text={account.steam_id} />
                            <CopyToClipboard text={toSteamID3(account.steam_id)} />
                            <CopyToClipboard text={toSteamID(account.steam_id)} />
                            <br />
                            <div>{gameBanDetail(account.game_bans)}</div>
                            <div>{vacBanDetail(account.vac_bans)}</div>
                            <div>{economyBanDetail(account.economy_ban)}</div>
                            {account.last_ban > 0 ? lastBanDetail(addDays(new Date(parseInt(account.last_checked.toString())), -1 * account.last_ban)) : null}
                            <br />
                            <div>
                                {format(parseInt(account.last_checked.toString()), "MM/DD/YYYY")}
                                <MediaQuery minWidth={900}><span style={{ color: "#aaa" }}> {distanceInWordsToNow(parseInt(account.last_checked.toString()))} ago</span></MediaQuery>
                            </div>
                            {fetchedFrom()}
                            {account.tracked_by ? account.tracked_by : null}
                        </BottomRight>
                    </div>
                </div>

            );
        }
    }

    return (
        <div style={{ backgroundColor: "#202938", width: "100%" }}>
            {accountData()}
        </div>
    );
};

export default Account;