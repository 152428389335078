import * as React from 'react';
import { AccountRow, GhostAccountRow } from './AccountRow';
// import { observer } from 'mobx-react';
import applicationState from '../../store/state';
import MediaQuery from 'react-responsive';
import useSWR from 'swr';

/**
 * Driving smart component for the app.
 *
 * @class VacList
 * @extends {React.Component<any, IVacList>}
 */

const pageSize = 20;
const GhostElements = Array.from(new Array(pageSize), (e, i) => <GhostAccountRow key={i} />)

const VacList = () => {

    const [totalPages, setTotalPages] = React.useState(1);
    const [banned, setBanned] = React.useState(false);

    const VirtualPage = ({ index }: { index: number }) => {
        const { data, isLoading, error } = useSWR(`/api/trackedAccounts/?count=${pageSize}&page=${index - 1}&banned=${banned}`, () => applicationState.getTrackedAccounts(pageSize, index, banned));
        if (isLoading) {
            return (
                <tbody>
                    {GhostElements}
                </tbody>
            );
        }
        if (error) return null;
        if (!data) return null;
        return (<tbody>
            {data.map(account => <AccountRow account={account} key={account.id} />)}
        </tbody>);
    }

    const pages = [];
    for (let i = 0; i < totalPages; i++) {
        pages.push(<VirtualPage index={i} key={i} />);
    }

    // const { data: trackedAccounts, error, isLoading } = useSWR('/api/trackedAccounts', () => applicationState.getTrackedAccounts())

    // if (profile && trackedAccounts && trackedAccounts.length) {

    //     for (let i = page * pageSize; i < ((page * pageSize) + pageSize); i++) {
    //         const account = trackedAccounts[i];
    //         if (account) {
    //             playerElements.push(
    //                 <AccountRow account={account} key={i} />
    //             );
    //         }
    //     }
    // }

    // if (state.trackedAccountsState === ETrackedAccountsState.None || state.trackedAccountsState === ETrackedAccountsState.Loading) {
    //     return (
    //         <>
    //             <table id='vac-table'>
    //                 <thead>
    //                     <tr className='accountHeader'>

    //                         <th style={{ maxWidth: '400px' }}>account</th>
    //                         <MediaQuery query='(max-width: 900px)' style={{ width: '300px' }}>
    //                             {(matches) => {
    //                                 return matches ? null : <th>created</th>
    //                             }}
    //                         </MediaQuery>
    //                         <th style={{ width: '100px' }}>bans</th>
    //                     </tr>
    //                 </thead>
    //             </table>
    //             <div style={{ backgroundColor: '#202938', width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

    //             </div>
    //         </>
    //     );
    // }

    const emptyMessage = () => {
        return (
            <div style={{ backgroundColor: '#202938', width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                    <div>You currently have no tracked accounts. Search for an account to track it.</div>
                </div>
            </div>
        );
    }

    if (pages.length === 0) {
        return emptyMessage();
    }

    return (
        <>
            <table id='vac-table'>
                <thead>
                    <tr className='accountHeader'>

                        <th style={{ maxWidth: '400px' }}>account</th>
                        <MediaQuery query='(max-width: 900px)' style={{ width: '300px' }}>
                            {(matches) => {
                                return matches ? null : <th>created</th>
                            }}
                        </MediaQuery>
                        <th style={{ width: '100px' }}>bans</th>
                    </tr>
                </thead>
                {/* <tbody> */}
                {pages}
                {/* </tbody> */}
                <tbody>
                    <tr onClick={() => setTotalPages(totalPages + 1)}><td colSpan={3} >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35px' }}>
                            Load more
                        </div>
                    </td></tr>
                </tbody>
            </table>
            <div className='vac-table-footer'>
                <MediaQuery query='(max-width: 900px)'>
                    {(matches) => {
                        if (matches) {
                            // return <PagingControl />;
                        }
                        else {
                            return (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ display: 'flex' }}>
                                        {/* <span style={{ paddingRight: '20px' }}>

                                            <span style={{ marginRight: '8px' }}>{0}% banned after track.</span>
                                            <InfoHover tooltip='Percentage of accounts tracked before being banned'></InfoHover>
                                        </span> */}
                                        <div className='link' onClick={() => { setBanned(!banned) }}>
                                            <span>{banned ? 'Show All' : 'Show Banned'}</span>
                                        </div>
                                    </div>

                                    {/* <div style={{ width: '300px' }}>
                                        <PagingControl />
                                    </div> */}
                                </div>
                            );
                        }
                    }}
                </MediaQuery>
            </div>
        </>
    );

};

export default VacList;
