import * as React from "react";

import { Link } from "react-router-dom";

import "./footer.css";
import MediaQuery from "react-responsive";

const Footer: React.FC = (props) => {

    const links = [
        ["stats", "/stats"],
        ["about", "/about"],
        ["privacy & terms", "/privacy"],
        ["changelog", "/changelog"],
        ["GitHub Issues", "https://github.com/sbuggay/vaclist-issues/issues"]
    ]

    const linkElems = () => links.map(([label, path], idx) => {
        return <React.Fragment key={idx}>
            {path[0] === "/"
                ? <Link className={"link"} style={{ borderRight: idx < links.length - 1 ? "1px solid gray" : 0, padding: "0 10px" }} to={path}>{label}</Link>
                : <a className={"link"} style={{ borderRight: idx < links.length - 1 ? "1px solid gray" : 0, padding: "0 10px" }} href={path}>{label}</a>
            }
            {/* {idx < (links.length - 1) ? <span className="delim">|</span> : null} */}
        </React.Fragment>;
    });

    const linkElemsMobile = () => {
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                {links.map(([label, path], idx) => {
                    return <Link style={{ width: "100%", height: "40px", lineHeight: "40px", textDecoration: "none", borderBottom: "1px solid gray" }} key={idx} to={path}>
                        {label}
                    </Link>

                })}
            </div>
        );
    }

    return (
        <MediaQuery query="(max-width: 900px)">
            {(matches) => {
                return <footer className={`${matches ? "mobile" : ""}`}>
                    <div className="container" style={{ height: "100%" }}>
                        <div className="footer-inner">
                            {matches ? linkElemsMobile() :
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <div>
                                        {[...linkElems()]}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </footer>
            }}
        </MediaQuery>
    );
};

export default Footer;