
import * as React from "react";

const AboutBlurb: React.FC = (props) => {
    return (
        <p>
            VacList allows you to view and track Steam accounts and their <a href="https://support.steampowered.com/kb/7849-RADZ-6869/">ban status</a>. 
            You can search for accounts by their SteamID, community URL, or vanity name. Logging in through Steam allows you to track accounts and view their ban status over time. VAC is supported in over one hundred games.
            You can view the full list of supported games <a href="https://store.steampowered.com/search/?sort_by=Type&category1=998&category2=8">here.</a>
        </p>
    );
};

export default AboutBlurb;

// Track accounts
// Get notifications when someone has been banned
