import * as React from "react";
// import { withRouter, RouteComponentProps } from "react-router";
import { useNavigate } from "react-router-dom";
import Bans from "../account/Bans";

// interface IProps extends RouteComponentProps<any> {
//     account: IAccount;
// }

const AccountPreview = (props: any) => {
    const account = props.account;

    const navigate = useNavigate();

    return (
        <div className="accountRow" onClick={() => navigate(`/account/${account.steam_id}`)}>
            <div style={{ display: "flex", alignItems: "center", overflowX: "hidden" }}>
                <img src={account.avatar} alt="avatar" />
                <div>
                    <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {account.personaname}
                    </div>
                    <div style={{ fontSize: "0.7rem" }}>
                        <a className="underline" style={{ color: "#999" }} onClick={e => e.stopPropagation()} href={account.profile_url} >{account.steam_id}</a>
                    </div>
                </div>
            </div>
            <div style={{ minWidth: "100px" }}>
                <Bans account={account} />
            </div>
        </div>
    );
};

export default AccountPreview;
