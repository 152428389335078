import MediaQuery from "react-responsive";

import { FaBullseye, FaShieldAlt, FaUser } from 'react-icons/fa/';
import { IStatsResult } from "../../../../server/db/Stats";

const HeroIcon = (props: { children?: React.ReactNode }) => {
    return <MediaQuery minWidth={900}>
        {(matches) => {
            return (
                <div style={{ width: matches ? "33%" : "" }}>
                    {props.children}
                </div>
            );
        }}
    </MediaQuery>
}

const StatHeroList = (props: { data: IStatsResult }) => {
    const { data } = props;
    return (
        <MediaQuery minWidth={900}>
            {(matches) => {
                return <div style={{ display: "flex", padding: "32px 0", flexDirection: matches ? "row" : "column", justifyContent: "space-around", textAlign: "center", alignItems: "center", minHeight: "150px", flex: "1 0 auto" }}>
                    <HeroIcon>
                        <div className="flex flex-col items-center">
                            <FaUser className="text-8xl mb-4" />
                            <div className="text-4xl">{data.accounts.toLocaleString()} Accounts</div>
                            <div className="text-neutral-400">Accounts that have been indexed by VacList</div>
                        </div>
                    </HeroIcon>
                    <HeroIcon>
                        <div className="flex flex-col items-center">
                            <FaBullseye className="text-8xl mb-4" />
                            <div className="text-4xl">{data.trackedCount.toLocaleString()} Tracked</div>
                            <div className="text-neutral-400">Accounts that have been tracked</div>
                        </div>
                    </HeroIcon>
                    <HeroIcon>
                        <div className="flex flex-col items-center">
                            <FaShieldAlt className="text-8xl mb-4" />
                            <div className="text-4xl">{data.percent.toLocaleString()}% Banned</div>
                            <div className="text-neutral-400">Percentage of accounts with at least one ban</div>
                        </div>
                    </HeroIcon>
                </div>;
            }}
        </MediaQuery>
    );
}
export default StatHeroList;
