import * as React from "react";
import MediaQuery from "react-responsive";
import { useLocation } from "react-router-dom";

const LoginButton = () => {

    const location = useLocation();

    return (

        <a style={{ marginLeft: "20px", height: "30px" }} href={`/steam/login?redirect=${encodeURIComponent(location.pathname)}`}>
            <MediaQuery minWidth={900}>
                {(matches) => {
                    return matches ?
                        <img alt="login" style={{ height: "30px" }} src="https://steamcommunity-a.akamaihd.net/public/images/signinthroughsteam/sits_01.png" />
                        : <img alt="login" style={{ height: "30px" }} src="https://steamcommunity-a.akamaihd.net/public/images/signinthroughsteam/sits_02.png" />

                }}
            </MediaQuery>
        </a>

    );
};

export default LoginButton;
