import * as React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './header/Header';
import Account from './account/Account';
import Stats from './stats/Stats';
import About from './about/About';


import Home from './Home';
import Admin from './admin/Admin';
import Privacy from './privacy/Privacy';
import Footer from './footer/Footer';
import Banned from './banned/Banned';
import Changelog from './Changelog';
// import ScrollToTop from './utilities/ScrollToTop';
import MediaQuery from 'react-responsive';
import Batch from './batch/Batch';
import Beta from './beta/Beta';
import Settings from './settings/Settings';
import 'tippy.js/dist/tippy.css';
import './grid/grid.css';

import { SWRConfig } from 'swr';
import ScrollToTop from './utilities/ScrollToTop';
import AccountProvider from './account/AccountProvider';

// console.log('Howdy partner 🤠');

// class Analytics extends React.Component<RouteComponentProps<any>> {
//     componentDidMount() {
//         this.sendPageChange(this.props.location.pathname, this.props.location.search)
//     }

//     componentDidUpdate(prevProps: RouteComponentProps<any>) {
//         if (this.props.location.pathname !== prevProps.location.pathname
//             || this.props.location.search !== prevProps.location.search) {
//             this.sendPageChange(this.props.location.pathname, this.props.location.search)
//         }
//     }

//     sendPageChange(pathname: string, search: string = '') {
//         const page = pathname + search;
//         ReactGA.set({ page });
//         ReactGA.pageview(page);
//     }

//     render() {
//         return null
//     }
// }

// const AnalyticsTracker = () => {
//     return <Route component={Analytics} />
// }


const App: React.FC = () => {
    return (
        <SWRConfig value={{
            revalidateOnFocus: false,
            revalidateIfStale: false,
            refreshInterval: 0,
            shouldRetryOnError: false,
        }}>
            <AccountProvider>
                <Router>
                    <ScrollToTop />
                    {/* <AnalyticsTracker /> */}
                    <div className='pageContainer'>
                        <MediaQuery query='(max-width: 900px)'>
                            {(matches) => {
                                return (
                                    <div className={`contentWrap${matches ? ' mobile' : ''}`}>
                                        <Header />
                                        <main className='container'>
                                            <Routes>
                                                <Route path='/' element={<Home />} />
                                                <Route path='/account/:id' element={<Account />} />
                                                <Route path='/stats' element={<Stats />} />
                                                <Route path='/about' element={<About />} />
                                                <Route path='/admin' element={<Admin />} />
                                                <Route path='/settings' element={<Settings />} />
                                                <Route path='/privacy' element={<Privacy />} />
                                                <Route path='/banned' element={<Banned />} />
                                                <Route path='/changelog' element={<Changelog />} />
                                                <Route path='/batch' element={<Batch />} />
                                                <Route path='/beta' element={<Beta />} />
                                                <Route element={<Home />} />
                                            </Routes>
                                        </main>
                                    </div>
                                );
                            }}
                        </MediaQuery>
                        <Footer />

                    </div>
                </Router>
            </AccountProvider>
        </SWRConfig>
    );
}

export default App;